<template>
  <div class="layout-complete">
    <AppHeader />
    <div class="flex-grow-1 d-flex flex-column">
      <router-view class="page flex-grow-1 app-content-wrapper" />
    </div>
    <AppFooter />
    <ConfirmBox />
    <AlertBox />
  </div>
</template>

<script>

import rolesMixin from '@/mixins/roles';

export default {
  name: 'layout-complete-layout',
  mixins: [rolesMixin],
  components: {
    AppHeader: () => import('@/views/components/Layout/Header'),
    AppFooter: () => import('@/views/components/Layout/Footer'),
    AlertBox: () => import(/* webpackChunkName: "component-alert-modal" */ '@/views/components/Layout/AlertBox.vue'),
    ConfirmBox: () => import(/* webpackChunkName: "component-confirm-modal" */ '@/views/components/Layout/ConfirmBox'),
  },
};

</script>

<style lang="scss">

$sidebar-border-right-color: #1C2023 !default;
$sidebar-size-header-height-mobile: 82px !default;

.main-header {
  height: $sidebar-size-header-height-mobile;
}

.page {
  padding: 10px 25px;
  max-width: 100%;
  overflow: auto;
}

.app-content-wrapper {
  height: calc(100vh - (#{$sidebar-size-header-height-mobile + $main-footer-height}));
}

</style>
